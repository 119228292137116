import React from 'react';
import Helmet  from 'react-helmet'

export class BasePage extends React.Component<{title?: string, className?: string, children: React.ReactNode}, {}> {

    constructor(props) {
        super(props);
    }

    public render() {
        return <div key="BASE PAGE" className={this.props.className}>
            <Helmet
                key="helm"
                title={`${this.props.title ? `(${this.props.title})` : "SLAP PUTIN"} - My Little Putin `}
                meta={[
                    { name: "viewport", content: "width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=0"},
                    // { name:"apple-mobile-web-app-capable" , content:"yes"},
                    { name: 'description', content: 'Slap Putin!! Create your own little Putin and share with friends! #mylittleputin' },
                    { name: 'keywords', content: 'My, Little, Putin, MyLittlePutin, My Little Putin, Putin, Slap Putin, Slap, Putin' },
                    { name: 'image', content: '/lol.png'},
                    { name: 'og:image', content: '/lol.png'},
                    { name: 'og:image:type', content: 'image/png'},
                    { name: 'og:image:width', content: '1000'},
                    { name: 'og:image:height', content: '1000'}
                ]}
                > 
                <link rel="preconnect" href="https://fonts.googleapis.com" />
                <link rel="preconnect" href="https://fonts.gstatic.com" crossOrigin="true"/>
                <link href="https://fonts.googleapis.com/css2?family=Arimo:wght@400;700&display=swap" rel="stylesheet"/>
                <link href="https://fonts.googleapis.com/css2?family=Nunito:wght@400;700;900&family=Roboto:wght@400;700;900&family=Rubik+Mono+One&display=swap" rel="stylesheet"></link>
            </Helmet>
            {this.props.children}
        </div>
    }
}