import React from 'react';
import { AnvsVector } from '../../../anvs';

const LazyLocalPutins = React.lazy(() =>
    import("./LocalPutins")
)

type _props = {expanded?: boolean, size: AnvsVector, show?: number}

export class LocalPutinsLazy extends React.Component<_props, {}> {
    constructor(props: _props) { super(props); }
    render(): React.ReactNode {
        const ssr = typeof window === "undefined"
        return ssr ? null : <React.Suspense key="lazy_putins" fallback={<div/>}>
            <LazyLocalPutins {...this.props} />
        </React.Suspense>
    }
}
