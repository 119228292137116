import React from 'react';

import logo from "../../static/assets/mlp logo.svg"
import anvsLogo from "../../static/assets/anvs logo.png"
import bogLogo from "../../static/assets/bog_logo.png"
import hmwrkLogo from "../../static/assets/hmwrk logo.png"

import { ContentCard } from './ContentCard';

// tslint:disable-next-line:no-var-requires
require("../pages/index.less");

export class PutinFooter extends React.Component<{goHome?: boolean}, {}> {

    render(): React.ReactNode {
        

        const footer = [
            

            <a key="mlp home" href={this.props.goHome ? "/" : null} > <img key="logo" className="logoBottom" src={logo} /> </a>,

            <ContentCard key="footer" children={[
                <a key="hmwrk" href="https://hmwrk.net" ><img className="invertDark" style={{float:"right", position:"relative", right: "0", bottom: "0", maxHeight: "50px", padding: "10px", }} src={hmwrkLogo} /></a>,
                {type: "Note", content: <p>by <a href="https://bog.computer" ><img  className="invertDark" style={{display: "inline-block", maxHeight: "20px",}} src={bogLogo} /></a></p>},

                {type: "Note", content: <p>using <a href="https://anvs.io" > anvs.io</a></p>},
                {type: "Title", style:{fontFamily: "sans-serif"}, content: "Путин хуйло"}
            ]} />,

        ]

        return <div key="FOOTER CONTAINER" children={footer}></div>
    }
}
