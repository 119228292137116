import React, { ReactElement, ReactNode } from 'react';

// tslint:disable-next-line:no-var-requires
require("./ContentCard.less");


interface ContentCardRowProps extends React.HTMLProps<HTMLDivElement> {
  children: (ReactElement<ContentCard>)[],
}

export class ContentCardRow extends React.Component<ContentCardRowProps, {}> {

  constructor(props) {
    super(props);
    this.state = { };
  }

  public render() {
    const { } = this.state;
    const data = this.props;

    return <div className={ "ContentCard-Row" }  >
        { this.props.children.map( (c) => { return <div className={ "ContentCard-DoubleColumn" }  > { c } </div>} ) }
    </div>;
  }
}

type ContentType = "Title" | "Heading1" | "Heading2" | "Heading3" | "Text" | "Warning" | "Note" | "Other"
interface Content {
  type: ContentType,
  content: string | ReactNode,
  style?: React.CSSProperties
} 

interface ContentCardProps extends React.HTMLProps<HTMLDivElement> {
  children: (Content | ReactNode)[],
  collapsable?: {
    startOpen?: boolean,
    numberVisibleWhenClosed?: number,
    collapseText?: string,
    expandText?: string
  }
  border?: boolean
}

const isContent = (object: any): object is Content => {
  return object && typeof object === 'object' && 'type' in object && 'content' in object;
}

interface ContentCardState extends React.HTMLProps<HTMLDivElement> {
  show: boolean,
}

var _globalCount = 0

export class ContentCard extends React.Component<ContentCardProps, ContentCardState> {

  private canCollapse = false;
  private numberVisibleWhenClosed = 0;
  private expandText = "Show All";
  private collapseText = "Hide";

  private cardCount = _globalCount++;

  constructor(props) {
    
    super(props);

    this.canCollapse = this.props.collapsable != null;

    const {startOpen, numberVisibleWhenClosed, collapseText, expandText} = this.props.collapsable ?? {};

    this.numberVisibleWhenClosed = numberVisibleWhenClosed ?? 0;
    this.collapseText = collapseText ?? this.collapseText;
    this.expandText = expandText ?? this.expandText;
    
    this.state = { show: startOpen ?? false };
  }

  build(element: ReactNode | Content, key: string, first: boolean, last: boolean): React.ReactNode {

    var eContent: Content;

    if(isContent(element)) {
      eContent = element;
    } else {
      eContent = {type: "Other", content: element};
    }

    const {type, content, style} = eContent;

    const CustomTag = (typeof content === "string" || content instanceof String) ? 
      ( type == "Heading1" ? 'h1' : type == "Heading2" ? 'h2' : type == "Heading3" ? 'h3' : 'p') :
      "div"

    var child: React.ReactNode = type == "Other" ? content : 
      <CustomTag
        key={key} 
        className={(first ? "ContentCard-First " : "") + (last ? "ContentCard-Last " : "") + "" + "ContentCard-" + type  } 
        style={style}> { 
        content
      }</CustomTag>

    return child;
  }

  public render() {
    const data = this.props;

    var newChildren: [any?] = [];

    this.props.children?.some((element, i) => {
      if(this.canCollapse && !this.state.show && i >= this.numberVisibleWhenClosed) {
        return true; // break
      }
      var node = this.build(element, "kContentCardChild_" + i.toString(), i == 0, i==this.props.children.length-1)
      newChildren.push(node);
    });

    if(this.canCollapse) {
      var collapser = this.build({
        type: "Other", 
        content: <div key="collapse container" className="ContentCard-CollapseButton"> 
            <button key="collapse" onClick={()=>{this.setState({show: !this.state.show});}} className="ContentCard-CollapseButtonLink" > {this.state["show"] ? this.collapseText : this.expandText} </button>
        </div>
      }, "collapser", false, false);

      // newChildren.push(collapser)
    }

    return <div key="CONTENT CARD CONTAINER" style={this.props.style} className={ "ContentCard" + (this.props.border ?? false ? "-Bordered" : "") + " " + (this.props.className ?? "") }  >
      {newChildren}
    </div>;
  }
}